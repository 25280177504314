<template>
  <div>
    <b-card>
      <p
        @click="generatedCard = !generatedCard"
        block
        v-b-toggle.generatedCard
        variant="light"
        class="col-12"
      >
        <b-row class="justify-content-between">
          <b-col md="auto">
            <b-button :to="{ name: 'task-invoice' }">
              <feather-icon icon="FilePlusIcon" size="15" /> Nouvelle Facture
            </b-button>
          </b-col>
          <b-col md="auto">
            <b-button
              variant="warning"
              class="ml-2"
              @click="alertFortUnpaidInvoiceModal = true"
              :disabled="alertIsProgress"
            >
              <feather-icon icon="SendIcon" size="15" v-if="!alertIsProgress" />
              <b-spinner small v-if="alertIsProgress" />
              Relancer les impayés
            </b-button>
            <b-dropdown
              :disabled="exportIsProgress"
              variant="outline-primary"
              id="dropdown-1"
              class="ml-2"
            >
              <template #button-content>
                <b-spinner v-if="exportIsProgress" small />
                <span>Exporter</span>
              </template>
              <b-dropdown-item @click="exportToExcel">
                <feather-icon icon="FileTextIcon" size="15" /> Exporter en
                Excel</b-dropdown-item
              >
              <b-dropdown-item @click="downloadCompressedInvoicesFile">
                <feather-icon icon="FileIcon" size="15" /> Exporter en
                PDF</b-dropdown-item
              >
            </b-dropdown>
            <b-button
              :to="{ name: 'invoice-corbeille' }"
              variant="outline-danger"
              class="ml-2"
            >
              <feather-icon icon="TrashIcon" size="15" />
              Corbeille</b-button
            >
          </b-col>
        </b-row>
      </p>
      <hr />
      <div>
        <b-row>
          <b-col md="4">
            <b-form-group>
              <label for="">Clients :</label>
              <multiselect
                v-model="customerMultiSelect"
                placeholder="Filtrer par client"
                :options="clients"
                :custom-label="customerName"
                :value="customerMultiSelect"
                selectLabel=""
                deselectLabel=""
                :showNoResults="false"
              >
                Pas d'element trouvé
              </multiselect>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="">Date de début du projet :</label>
              <b-input v-model="filterData.startDate" type="date"></b-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <label for="">Date de fin du projet :</label>
              <b-input v-model="filterData.endDate" type="date"></b-input>
            </b-form-group>
          </b-col>
          <b-col md="4">
            <b-form-group>
              <b-checkbox v-model="filterData.paid">
                Uniquement les factures encaissées
              </b-checkbox>
            </b-form-group>
          </b-col>
        </b-row>
      </div>
      <b-card-body>
        <div class="d-flex justify-content-between flex-wrap">
          <!-- sorting  -->
          <b-form-group
            label="Filtrer par :"
            label-size="sm"
            label-align-sm="left"
            label-cols-sm="4"
            label-for="sortBySelect"
            class="mr-1 mb-md-0"
          >
            <b-input-group size="sm">
              <b-form-select
                id="sortBySelect"
                v-model="sortBy"
                :options="sortOptions"
              >
              </b-form-select>
              <b-form-select v-model="sortDesc" size="sm" :disabled="!sortBy">
                <option :value="false">Croissant</option>
                <option :value="true">Décroissant</option>
              </b-form-select>
            </b-input-group>
          </b-form-group>

          <!-- filter -->
          <b-form-group
            label-cols-sm="2"
            label-align-sm="left"
            label-size="sm"
            label-for="filterInput"
            class="mb-0"
          >
            <b-input-group size="sm">
              <b-form-input
                id="filterInput"
                v-model="filter"
                type="search"
                placeholder="Recherche..."
              />
              <b-input-group-append>
                <b-button :disabled="!filter" @click="filter = ''">
                  Effacer
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </b-form-group>
        </div>
      </b-card-body>
      <b-table
        :busy="isBusy"
        style="height: 57vh"
        empty-text="Aucune facture disponible"
        empty-filtered-text="Aucune facture trouvée"
        show-empty
        head-variant="dark"
        responsive
        :per-page="perPage"
        :current-page="currentPage"
        class="generated-card"
        sticky-header="5"
        striped
        :items="invoices"
        :fields="fields"
        :sort-by.sync="sortBy"
        :sort-desc.sync="sortDesc"
        :sort-direction="sortDirection"
        :filter="filter"
        :filter-included-fields="filterOn"
        @filtered="onFiltered"
      >
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong> Chargement...</strong>
          </div>
        </template>

        <template #cell(is_paid)="data">
          <b-form-checkbox
            :disabled="userInfo.id != 14"
            v-b-tooltip.hover
            :title="
              data.item.is_paid
                ? 'Marquer comme impayée'
                : 'Marquer comme payée '
            "
            :checked="true"
            v-model="data.item.is_paid"
            class="custom-control-primary"
            @change="_markInvoiceIsPaid(data.item)"
          />
        </template>
        <template #cell(total)="data">
          <span class="p-1">{{ totalTTC(data.item) }}</span>
        </template>
        <template #cell(num)="data">
          <p>{{ data.item.num }}</p>
        </template>
        <template #cell(customer)="data">
          <b-link
            @click="fetchInvoices(data.item)"
            no-prefetch
            v-b-toggle.invoices-sidebar
          >
            {{ data.item.customer.name }}</b-link
          >
          <br />
          <small class="text-warning" v-if="data.item.is_sent == 0">
            En attente d'envoi
          </small>
          <small class="text-success" v-if="data.item.is_sent == 1">
            Facture envoyée au client
          </small>
        </template>
        <template #cell(actions)="data">
          <b-dropdown
            variant="link"
            no-caret
            class="chart-dropdown"
            toggle-class="p-0"
            right
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="18"
                class="text-body cursor-pointer"
              />
            </template>

            <b-dropdown-item
              v-if="data.item.num != null"
              :href="data.item.filename_invoice"
              target="_blank"
            >
              <feather-icon
                icon="EyeIcon"
                size="18"
                style="margin-right: 5px"
              />
              Voir la facture
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.is_paid == 0"
              :to="'/edit/task/invoice/' + data.item.id"
              button
            >
              <feather-icon
                icon="EditIcon"
                size="18"
                style="margin-right: 5px"
              />
              Éditer la facture
            </b-dropdown-item>
            <b-dropdown-item button @click="showInvoiceModal(data.item)">
              <feather-icon
                icon="SendIcon"
                size="18"
                style="margin-right: 5px"
              />
              Envoyer au client
            </b-dropdown-item>
            <b-dropdown-item button @click="shareInvoiceShowModal(data.item)">
              <feather-icon
                icon="ShareIcon"
                size="18"
                style="margin-right: 5px"
              />
              Partager la facture
            </b-dropdown-item>
            <b-dropdown-item
              v-if="data.item.is_paid == 0"
              button
              @click="(cancelInvoiceModal = true), (invoice = data.item)"
            >
              <feather-icon
                icon="FileIcon"
                size="18"
                style="margin-right: 5px"
                class="text-danger"
              />
              <span class="text-danger">Générer une facture d'avoir</span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
        <template #cell(date)="data">
          Edition : {{ data.item.created_at }}
          <br />
          <span class="text-danger" v-if="data.item.due_date"
            >Echeance : {{ data.item.due_date_string }}
          </span>
        </template>
        <template #cell(#)>
          <img src="../../../../public/pdf.svg" width="25" alt="" />
        </template>
      </b-table>

      <b-modal
        size="sm"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        v-model="showSendingModal"
        id="modal-center"
        centered
        :title="'Envoi de Facture N° : ' + invoice.num"
      >
        <!-- <h4>
          Facture de : <b>{{ invoice.customer.name }}</b>
        </h4> -->
        <b-overlay :show="loading" rounded="sm">
          <p>Voulez-vous vraiment envoyer cette facture au client ?</p>
          <br />
          <div class="text-center">
            <b-button
              @click="(showSendingModal = false), (showModal = false)"
              class="mr-1"
              variant="outline-primary"
              size="sm"
              >Non, annuler</b-button
            >
            <b-button size="sm" @click="sendInvoice(invoice)"
              >Oui, envoyer</b-button
            >
          </div>
        </b-overlay>
      </b-modal>
      <!-- SHARE INVOICE STARTING MODAAL -->
      <b-modal
        size="sm"
        hide-footer
        hide-header-close
        no-close-on-backdrop
        v-model="shareInvoiceModal"
        id="modal-center"
        centered
        :title="'Partager la Facture N° : ' + invoice.num"
      >
        <b-overlay :show="loading" rounded="sm">
          <p>Avec :</p>
          <validation-observer ref="shareInvoiceForm">
            <b-form>
              <b-form-group>
                <ky-input
                  :placeholder="'E-mail*'"
                  :type="'email'"
                  :rules="'required'"
                  v-model="share.email"
                />
              </b-form-group>
              <b-form-group>
                <ky-input
                  :placeholder="'E-mail en copie'"
                  :type="'email'"
                  :rules="''"
                  v-model="share.cc"
                />
              </b-form-group>
              <b-form-group>
                <ky-input
                  :placeholder="'Sujet'"
                  :type="'text'"
                  :rules="''"
                  v-model="share.subject"
                />
              </b-form-group>
              <b-form-group>
                <validation-provider
                  :rules="'required'"
                  #default="{ errors }"
                  name="Message"
                >
                  <b-textarea
                    placeholder="message..."
                    v-model="share.message"
                  ></b-textarea>
                  <small class="text-danger"> {{ errors[0] }} </small>
                </validation-provider>
              </b-form-group>
            </b-form>
          </validation-observer>
          <div class="text-center">
            <b-button
              :disabled="sharing"
              size="sm"
              @click="shareInvoiceFunction(invoice)"
            >
              <b-spinner v-if="sharing" small type="grow"></b-spinner>
              Partager</b-button
            >
            <b-button
              class="ml-1"
              variant="outline-danger"
              size="sm"
              @click="(invoice = {}), (shareInvoiceModal = false)"
              >Fermer</b-button
            >
          </div>
        </b-overlay>
      </b-modal>
      <!-- SHARING INVOICE MODEL END -->

      <b-card-body class="d-flex justify-content-between flex-wrap pt-0">
        <!-- page length -->
        <b-form-group
          label="Affichage"
          label-cols="6"
          label-align="left"
          label-size="sm"
          label-for="sortBySelect"
          class="text-nowrap mb-md-0 mr-1"
        >
          <b-form-select
            id="perPageSelect"
            v-model="perPage"
            size="sm"
            inline
            :options="pageOptions"
          />
        </b-form-group>

        <!-- pagination -->
        <div>
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            first-number
            last-number
            prev-class="prev-item"
            next-class="next-item"
            class="mb-0"
          >
            <template #prev-text>
              <feather-icon icon="ChevronLeftIcon" size="18" />
            </template>
            <template #next-text>
              <feather-icon icon="ChevronRightIcon" size="18" />
            </template>
          </b-pagination>
        </div>
      </b-card-body>
    </b-card>
    <!-- INVOICES SIDEBAR -->
    <b-sidebar
      no-close-on-backdrop
      no-header-close
      :title="'Liste des factures de : ' + client.name"
      id="invoices-sidebar"
      ref="mySideBar"
      bg-variant="white"
      width="1000px"
      right
      shadow
    >
      <template #footer="{ hide }">
        <div class="d-flex bg-dark text-light align-items-center px-1 py-1">
          <strong class="mr-auto"></strong>
          <b-button size="sm" @click="hide">Fermer</b-button>
        </div>
      </template>
      <b-card>
        <div class="px-1 py-1">
          <div>
            <b-table
              empty-text="Aucune factures pour ce client"
              show-empty
              responsive
              head-variant="dark"
              striped
              :items="allCustomerInvoices"
              :fields="CustomerInvoicesfields"
            >
              <template #cell(#)>
                <img src="../../../../public/pdf.svg" width="25" alt="" />
              </template>
              <template #cell(customer)="data">
                {{ data.item.customer.name }}
              </template>
              <template #cell(date)="data">
                {{ data.item.created_at }}
              </template>
              <template #cell(is_paid)="data">
                <b-form-checkbox
                  :disabled="userInfo.id != 18"
                  v-b-tooltip.hover
                  :title="
                    data.item.is_paid
                      ? 'Marquer comme impayée'
                      : 'Marquer comme payée '
                  "
                  :checked="true"
                  v-model="data.item.is_paid"
                  class="custom-control-primary"
                  @change="_markInvoiceIsPaid(data.item)"
                />
              </template>
              <template #cell(actions)="data">
                <b-dropdown
                  variant="link"
                  no-caret
                  class="chart-dropdown"
                  toggle-class="p-0"
                  right
                >
                  <template #button-content>
                    <feather-icon
                      icon="MoreVerticalIcon"
                      size="18"
                      class="text-body cursor-pointer"
                    />
                  </template>

                  <b-dropdown-item
                    v-if="data.item.is_validate == true"
                    :href="data.item.filename_invoice"
                    target="_blank"
                    variant="outline-primary"
                    size="sm"
                  >
                    <feather-icon
                      icon="DownloadIcon"
                      size="18"
                      style="margin-right: 5px"
                    />
                    Télécharger la facture
                  </b-dropdown-item>
                  <b-dropdown-item
                    v-else
                    :href="data.item.filename_devis"
                    target="_blank"
                    variant="outline-primary"
                    size="sm"
                  >
                    <b-icon icon="MoreVerticalIcon"> </b-icon>
                    <feather-icon
                      icon="DownloadIcon"
                      size="18"
                      style="margin-right: 5px"
                    />
                    Télécharger le devis
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-table>
          </div>
        </div>
      </b-card>
    </b-sidebar>
    <b-modal
      hide-header-close
      v-model="cancelInvoiceModal"
      ok-title="Oui, continuer"
      cancel-title="Non, annuler"
      ok-variant="danger"
      header-bg-variant="danger"
      title-class="text-white"
      cancel-variant="outline-primary"
      title="Attention"
      @cancel="(invoice = {}), (cancelInvoiceModal = false)"
      @ok="cancelInvoice(invoice)"
    >
      <p>
        Voulez-vous vraiment générer cette facture d'avoir ? <br />
        Cette action est irréversible.
      </p>
    </b-modal>

    <b-modal
      hide-header-close
      v-model="alertFortUnpaidInvoiceModal"
      ok-title="Oui, relancer"
      cancel-title="Non, annuler"
      ok-variant="warning"
      header-bg-variant="warning"
      title-class="text-white"
      cancel-variant="outline-primary"
      title="Attention"
      @cancel="alertFortUnpaidInvoiceModal = false"
      @ok="sendAlertUnpaidInvoices"
    >
      <p>Voulez-vous vraiment relancer les impayés ?</p>
    </b-modal>
  </div>
</template>

<script>
import { BToast } from "bootstrap-vue";
import KyInput from "@/@core/layouts/components/ky-input.vue";
import ToastificationContent from "@/@core/components/toastification/ToastificationContent.vue";
import { mapState, mapActions } from "vuex";
import moment from "moment";
import Multiselect from "vue-multiselect";
import FeatherIcon from "@/@core/components/feather-icon/FeatherIcon.vue";
export default {
  components: {
    BToast,
    ToastificationContent,
    FeatherIcon,
    Multiselect,
    KyInput,
  },
  data() {
    return {
      shareInvoiceModal: false,
      share: {
        invoice_id: null,
        email: "",
        cc: "",
        subject: "Partage de facture",
        message: "",
      },
      sharing: false,
      shareDefault: {
        invoice_id: null,
        email: "",
        cc: "",
        subject: "Partage de facture",
        message: "",
      },
      customerMultiSelect: null,
      cancelInvoiceModal: false,
      alertFortUnpaidInvoiceModal: false,
      alertIsProgress: false,
      client: {},
      today: new Date(),
      isBusy: false,
      sendIsProgress: false,
      invoice: null,
      loading: false,
      isNumNull: false,
      text: "This will get copied!",
      showSendingModal: false,
      invoice: {
        num: "",
      },
      showModal: false,
      perPage: 10,
      pageOptions: [10, 15, 20],
      totalRows: 1,
      currentPage: 1,
      sortBy: "date",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],

      filterData: {
        customerId: null,
        startDate: "",
        endDate: "",
        paid: null,
      },

      instanceCard: false,
      generatedCard: true,
      fields: [
        { key: "num", label: "Numéro", sortable: true },
        { key: "customer", label: "Client", sortable: true },
        { key: "date", label: "Date", sortable: true },
        { key: "totalTTC", label: "Total TTC(€)", sortable: true },
        {
          key: "is_paid",
          label: "Encaisée",
          sortable: true,
          thStyle: { width: "1%" },
        },
        { key: "actions", thStyle: { width: "5%" } },
      ],
      CustomerInvoicesfields: [
        {
          key: "num",
          label: "Numéro",
          sortable: true,
        },
        {
          key: "totalTTC",
          label: "Total TTC(€)",
          sortable: true,
        },
        {
          key: "is_paid",
          label: "Soldée",
          sortable: true,
        },
        {
          key: "date",
          sortable: true,
        },
        {
          key: "actions",
          thStyle: { width: "30px" },
        },
      ],
      exportIsProgress: false,
    };
  },
  computed: {
    ...mapState("enterprise", ["clients"]),

    ...mapState("tasks", [
      "invoices",
      "allInvoiceAndDevisDeleted",
      "allCustomerInvoices",
    ]),
    ...mapState("auth", ["userInfo"]),

    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },

  watch: {
    invoices(val) {
      this.totalRows = val.length;
    },

    filterData: {
      deep: true,
      handler() {
        this.isBusy = true;
        this.filterData.paid == true
          ? (this.filterData.paid = 1)
          : (this.filterData.paid = 0);
        this.invoiceGlobalFilter(this.filterData).then(() => {
          this.isBusy = false;
        });
      },
    },

    customerMultiSelect(val) {
      if (val) {
        this.filterData.customerId = val.id;
      } else {
        this.filterData.customerId = null;
      }
    },
  },

  mounted() {
    this.fetchCustomers();
    this.isBusy = true;
    this.allInvoices().then(() => {
      this.isBusy = false;
    });

    this.totalRows = this.invoices.length;
  },

  methods: {
    shareInvoiceShowModal(invoice) {
      this.invoice = invoice;
      this.shareInvoiceModal = true;
    },

    async shareInvoiceFunction() {
      const isValid = await this.$refs.shareInvoiceForm.validate();
      if (!isValid) return;
      this.sharing = true;
      this.share.invoice_id = this.invoice.id;
      this.shareInvoiceToAnyOne(this.$formData(this.share))
        .then((res) => {
          this.sharing = false;
          this.shareInvoiceModal = false;
          this.share = this.shareDefault;
          this.$nextTick().then((response) => {
            this.$refs.shareInvoiceForm.reset();
          });
        })
        .catch((err) => {
          this.sharing = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erreur",
              icon: "EditIcon",
              variant: "danger",
            },
          });
        });
    },

    downloadCompressedInvoicesFile() {
      this.exportIsProgress = true;
      this.exportAllInvoicesPdf()
        .then((response) => {
          this.exportIsProgress = false;

          window.location.href = response;
        })
        .catch((error) => {
          this.exportIsProgress = false;
        });
    },

    sendAlertUnpaidInvoices() {
      this.alertIsProgress = true;
      this.sendAlertForUnpaidInvoices()
        .then((response) => {
          this.alertIsProgress = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Relance faite avec succès",
              icon: "BellIcon",
              variant: "success",
            },
          });
        })
        .catch((err) => {
          this.alertIsProgress = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Relance échouée",
              icon: "AlertTriangleIcon",
              variant: "error",
            },
          });
        });
    },
    customerName(customer) {
      return customer.name;
    },

    exportToExcel() {
      this.exportIsProgress = true;
      this.$store
        .dispatch("tasks/exportInvoiceToExcel", this.invoices)
        .then((res) => {
          const anchor = document.createElement("a");
          anchor.href = res;
          document.body.appendChild(anchor);
          anchor.click();
        })
        .catch((err) => {
          this.exportIsProgress = false;
        })
        .finally(() => {
          this.exportIsProgress = false;
        });
    },
    exportToPdf() {
      this.exportIsProgress = true;
      this.$store
        .dispatch("tasks/exportInvoiceToPdf", this.invoices)
        .then((res) => {
          const anchor = document.createElement("a");
          anchor.href = res;
          window.open(anchor.href, "_blank");
          document.body.appendChild(anchor);
          anchor.click();
        })
        .catch((err) => {
          this.exportIsProgress = false;
        })
        .finally(() => {
          this.exportIsProgress = false;
        });
    },
    ...mapActions("tasks", [
      "allInvoices",
      "allDevis",
      "customerInvoices",
      "sendValidatedInvoiceToCustomer",
      "updateThisInvoice",
      "removeThisInvoice",
      "invoicesAndInvoiceDeteled",
      "checkInvoiceIsPaid",
      "cancelThisInvoice",
      "exportAllInvoicesPdf",
      "sendAlertForUnpaidInvoices",
      "invoiceGlobalFilter",
      "shareInvoiceToAnyOne",
    ]),

    ...mapActions("enterprise", ["fetchCustomers"]),

    fetchInvoices(data) {
      this.customerInvoices(data.customer.id);
    },

    _markInvoiceIsPaid(data) {
      this.checkInvoiceIsPaid(data.id)
        .then((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Opération éffectuée avec succès",
              icon: "EditIcon",
              variant: "success",
            },
            queue: false,
            onClosed: () => {
              this.allInvoices();
            },
          });
        })
        .catch((e) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Erreur",
              icon: "EditIcon",
              variant: "danger",
            },
            queue: false,
            onClosed: () => {
              this.allInvoices();
            },
          });
        });
    },

    totalTTC(data) {
      var total = data.total;
      var discount = (data.discount * total) / 100;
      var tva = (total * data.tva) / 100;
      var tttc = total + tva - discount;
      if (Number.isInteger(total) == true) {
        return tttc;
      } else {
        return tttc.toFixed(2);
      }
    },

    trashed(data) {
      this.removeThisInvoice(data.num_enterprise_id).then((e) => {
        this.allInvoices();
        //this.invoicesAndInvoiceDeteled.push(data)
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Facture placée dans la corbeille",
            icon: "BellIcon",
            variant: "success",
          },
        });
      });
    },

    cancelInvoice(invoice) {
      this.cancelThisInvoice(invoice.id).then((e) => {
        this.allInvoices();
        this.cancelInvoiceModal = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Facture générée en facture d'avoir avec succès",
            icon: "BellIcon",
            variant: "success",
          },
        });
      });
    },

    resetForm() {
      this.showModal = false;
      this.invoice.num = null;
    },

    async updateInvoice() {
      const valid = await this.$refs.thisForm.validate();
      if (!valid) return;
      this.updateThisInvoice(this.$formData(this.invoice))
        .then((r) => {
          window.location.reload();
          this.showModal = false;
          this.showSendingModal = true;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Numéro de facture modifié avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {});
    },

    showInvoiceModal(data) {
      this.showSendingModal = true;
      this.showModal = true;
      this.invoice = data;
    },

    getDate(date) {
      return moment(date).locale("fr").format("D MMMM YYYY");
    },

    sendInvoice(data) {
      this.sendIsProgress = true;
      this.loading = true;
      this.sendValidatedInvoiceToCustomer(data.id)
        .then((e) => {
          this.allInvoices();
          this.showSendingModal = false;
          this.sendIsProgress = false;
          this.loading = false;
          this.showModal = false;
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Facture envoyée avec succès",
              icon: "EditIcon",
              variant: "success",
            },
          });
        })
        .catch((e) => {
          this.loading = false;
        });
    },
    info(item, index, button) {
      this.infoModal.title = `Row index: ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style scoped>
.generated-card {
  max-height: 70vh;
}
</style>
